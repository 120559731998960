/**
* This is a horrible thing that makes sure legacy JS files that have been migrated to ES modules
* are only loaded into the browser once. `jqModal` caused problems when it was loaded on the
* Datasets#show page, even though it worked fine at /admin/home. 
* 
* This can go away once we are sure that each file is only being loaded on a page one time, 
* but how that is accomplished could happen in many ways. One way would be to have Webpack only drop
* one JS bundle per page, but we are far away from that.
*/
declare global {
  interface Window { loadedModules: object }
}

const registry = (modulePath: string, exports: () => void) => {
  // This file will mostly be used in JS files, so this typecheck tries to prevent
  // misuse of this helper.
  if (typeof exports !== 'function') {
    throw new Error('exports must be a function');
  }

  window.loadedModules = window.loadedModules || {};
  let { loadedModules } = window;

  if (loadedModules[modulePath]) {
    console.debug(`attempted to add '${modulePath}' to window.loadedModules, but it is already loaded! `)
    return;
  } else {
    console.debug(`adding '${modulePath}' to window.loadedModules`)
    loadedModules[modulePath] = true;
    return exports();
  }
};

export default registry;
